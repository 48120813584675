import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import ContactSection from "../../components/contactSection"
import BrewguideOverviewComponent from "./brewguideOverviewComponent"
import HeroComponent from "../../components/heroComponent"

const BrewguidePage = () => (
  <Layout>
    <Seo title="Brew Guide" />

    <HeroComponent header="Brew Guide" subheader="Deine einfache Schritt für Schritt Anleitungen zu einem außergewöhnlichen Kaffee-Erlebnis, egal ob
            Siebträger, Vollautomat, Filter (V60, Chemex), Frenchpress oder Bialetti / Mokka Kanne.">
      <StaticImage src="../../images/nathan-dumlao-pMW4jzELQCw-unsplash_crop.png"
                   alt="Brew guide für Siebträger, Vollautomat, Filterkaffee und Mokka Kanne Bialetti"
                   className="w-full h-96 object-center object-cover"
                   FULL_WIDTH
      />
    </HeroComponent>


    <BrewguideOverviewComponent
      header="Siebträger"
      description="Mit unserer Siebträger Detailanleitung bekommst du einen Schritt für Schritt Guide wie du ganz einfach
            wirklich guten Kaffee zubereitest. Am Ende der Anleitung findest du noch ein paar hilfreiche Tipps & Tricks
            über die Zubereitung."
      linkto="siebtraeger"
      linkdescription="Siebträger Anleitung">
      <StaticImage
        className="w-full h-full object-cover"
        src="../../images/janko-ferlic-pR9D-7ztGmA-unsplash.jpg"
        alt="brew guide siebträger von caffeinity"
        FULL_WIDTH
      />
    </BrewguideOverviewComponent>

    <BrewguideOverviewComponent
      header="Filter v60"
      description="Mit unserer Filter Detailanleitung bekommst du einen Schritt für Schritt Guide wie du ganz einfach
            wirklich guten Kaffee mit deinem Filter zubereitest. Am Ende der Anleitung findest du noch ein paar hilfreiche Tipps & Tricks
            über die Zubereitung."
      linkto="filter"
      linkdescription="Filter V60 Anleitung">
      <StaticImage
        className="w-full h-full object-cover"
        src="../../images/brewguide/charlie-firth-4LbLyYESbFE-unsplash.jpg"
        alt="brew guide filter v60 chemex von caffeinity"
        FULL_WIDTH
      />
    </BrewguideOverviewComponent>


    <BrewguideOverviewComponent
      header="French Press"
      description="Mit unserer French Press Detailanleitung bekommst du einen Schritt für Schritt Guide wie du ganz einfach
            wirklich guten Kaffee mit deinem French Press zubereitest. Am Ende der Anleitung findest du noch ein paar hilfreiche Tipps & Tricks
            über die Zubereitung."
      linkto="frenchpress"
      linkdescription="French Press Anleitung">>
      <StaticImage
        className="w-full h-full object-cover"
        src="../../images/brewguide/frenchpress.jpeg"
        alt="brew guide siebträger von caffeinity"
      />
    </BrewguideOverviewComponent>

    <ContactSection />

  </Layout>
)

export default BrewguidePage
